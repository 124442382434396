import {ReviewItemType} from "../types/ReviewItemType";

export const reviewsData: ReviewItemType[] = [
    {
        name: 'Александр Никифоров',
        comment: 'Очень благодарен за профессиональную помощь в решении моего дела. Отличная работа адвокатов!',
        rating: 5
    },
    {
        name: 'Виктория Сергеевна',
        comment: 'Команда юристов проявила высокий уровень компетентности и внимания к деталям. Мои ожидания полностью оправданы.',
        rating: 5
    },
    {
        name: 'Михаил Орлов',
        comment: 'Сомневался в успехе, но благодаря грамотной поддержке адвоката смог выиграть дело. Спасибо!',
        rating: 4
    },
    {
        name: 'Жакупова Асем',
        comment: 'Мен осы заң фирмасымен ынтымақтастығыма өте қуаныштымын. Барлық мәселелер жедел және кәсіби түрде шешілді.',
        rating: 5
    },
    {
        name: 'Рыскулов Данияр',
        comment: 'Мен осы заңгерлерді ұсынамын! Құзыретті, мұқият, үнемі байланыста. Олар менің мәселемді қысқа мерзімде шешті.',
        rating: 4
    },

];