// eslint-disable-next-line react/require-default-props
export default function WorkHoursIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
                <path
                    d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"/>
                <path fill="currentColor"
                      d="M17.03 14.758a1 1 0 0 1 1.962.37l-.022.115L18.28 18H19v-1a1 1 0 0 1 1.993-.116L21 17v1a1 1 0 0 1 .117 1.993L21 20v1a1 1 0 0 1-1.993.117L19 21v-1h-2a1 1 0 0 1-.993-1.113l.023-.13zM13.5 14a2.5 2.5 0 0 1 2.5 2.5v.325c0 .675-.241 1.327-.68 1.839L14.174 20H15a1 1 0 0 1 0 2h-3a1 1 0 0 1-.76-1.65l2.561-2.988a.83.83 0 0 0 .199-.537V16.5a.5.5 0 1 0-1 0a1 1 0 1 1-2 0a2.5 2.5 0 0 1 2.5-2.5M12 3a9 9 0 0 1 8.796 10.913a3 3 0 0 0-2.068-1.823a3 3 0 0 0-2.724.67A4.5 4.5 0 0 0 9 16.5c0 .886.384 1.682.994 2.23l-.272.318a3 3 0 0 0-.68 1.455A9 9 0 0 1 12 3m-1 3a1 1 0 0 0-1 1v3H9a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1"/>
            </g>
        </svg>
    )
}