// eslint-disable-next-line react/require-default-props
export default function PropertyDisputesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 512 512">
            <g clip-rule="evenodd" fill-rule="evenodd">
                <g>
                    <path
                        d="m114.557 30.63h28.194c6.672 0 12.13 5.458 12.13 12.13v59.846c0 6.672-5.458 12.13-12.13 12.13h-28.194c-6.672 0-12.13-5.458-12.13-12.13v-59.846c0-6.671 5.458-12.13 12.13-12.13z"
                        fill="#837683"></path>
                    <path
                        d="m127.587 30.63h15.163c6.672 0 12.13 5.458 12.13 12.13v59.845c0 6.672-5.458 12.13-12.13 12.13h-15.163c6.672 0 12.13-5.458 12.13-12.13v-59.845c0-6.672-5.458-12.13-12.13-12.13z"
                        fill="#685e68"></path>
                    <path
                        d="m412.892 119.715v233.871h-344.038v-233.871h-31.366c-6.089 0-7.886-8.354-2.337-10.859l205.723-92.856 205.722 92.856c5.55 2.504 3.751 10.859-2.337 10.859z"
                        fill="#eceff1"></path>
                    <path
                        d="m412.892 119.715v233.871h-21.404v-233.871zm-185.531-97.616 192.21 86.757c5.55 2.504 3.751 10.859-2.337 10.859h27.027c6.088 0 7.888-8.355 2.338-10.859l-205.725-92.856z"
                        fill="#d1d1d6"></path>
                    <path
                        d="m273.755 155.897h67.637c6.671 0 12.13 5.458 12.13 12.13v42.315c0 6.671-5.458 12.13-12.13 12.13h-67.637c-6.672 0-12.13-5.458-12.13-12.13v-42.315c0-6.672 5.457-12.13 12.13-12.13z"
                        fill="#d8ecfe"></path>
                    <path
                        d="m273.754 155.897h15.163c-6.672 0-12.13 5.458-12.13 12.13v42.315c0 6.672 5.458 12.129 12.13 12.129h-15.163c-6.672 0-12.13-5.458-12.13-12.129v-42.315c.001-6.672 5.459-12.13 12.13-12.13z"
                        fill="#c4e2ff"></path>
                    <path
                        d="m136.574 204.914h71.353c6.672 0 12.13 5.459 12.13 12.129v134.807c0 6.672-5.458 12.129-12.13 12.129h-71.353c-6.672 0-12.129-5.458-12.129-12.129v-134.806c-.001-6.671 5.457-12.13 12.129-12.13z"
                        fill="#ecba78"></path>
                    <path
                        d="m136.574 204.914h15.163c-6.672 0-12.13 5.458-12.13 12.13v134.807c0 6.672 5.458 12.129 12.13 12.129h-15.163c-6.672 0-12.13-5.457-12.13-12.129v-134.807c0-6.672 5.458-12.13 12.13-12.13z"
                        fill="#e3a76f"></path>
                    <path
                        d="m47.22 336.347h387.307c5.838 0 10.614 4.776 10.614 10.613v22.392c0 5.838-4.776 10.614-10.614 10.614h-387.307c-5.838 0-10.613-4.776-10.613-10.614v-22.392c0-5.837 4.776-10.613 10.613-10.613z"
                        fill="#837683"></path>
                    <path
                        d="m429.978 336.347h4.549c5.838 0 10.613 4.776 10.613 10.614v22.391c0 5.838-4.776 10.613-10.613 10.613h-387.307c-5.838 0-10.614-4.776-10.614-10.613v-4.549h382.758c5.837 0 10.613-4.776 10.613-10.613v-17.843z"
                        fill="#685e68"></path>
                </g>
                <g>
                    <path
                        d="m213.107 140.561h219.673c1.974 0 3.672.703 5.067 2.099l40.235 40.234c1.395 1.395 2.099 3.093 2.099 5.067v300.873c0 3.947-3.219 7.166-7.166 7.166h-259.908c-3.947 0-7.166-3.219-7.166-7.166v-341.107c0-3.947 3.219-7.166 7.166-7.166z"
                        fill="#8ac9fe"></path>
                    <path
                        d="m213.107 140.561h13.109v355.439h-13.109c-3.947 0-7.166-3.219-7.166-7.166v-341.107c0-3.947 3.219-7.166 7.166-7.166z"
                        fill="#60b7ff"></path>
                    <path
                        d="m435.749 141.182c.761.348 1.462.84 2.099 1.477l40.235 40.234c.637.637 1.13 1.338 1.478 2.099h-40.228c-1.973 0-3.583-1.61-3.583-3.583v-40.227z"
                        fill="#60b7ff"></path>
                    <path
                        d="m397.922 413.954c4.418 0 8 3.582 8 8s-3.582 8-8 8h-155.175c-4.418 0-8-3.582-8-8s3.582-8 8-8zm30.197 16c-4.418 0-8-3.582-8-8s3.582-8 8-8h15.254c4.418 0 8 3.582 8 8s-3.582 8-8 8zm0 32.472c-4.418 0-8-3.582-8-8s3.582-8 8-8h15.254c4.418 0 8 3.582 8 8s-3.582 8-8 8zm-30.197-16c4.418 0 8 3.582 8 8s-3.582 8-8 8h-155.175c-4.418 0-8-3.582-8-8s3.582-8 8-8z"
                        fill="#eceff1"></path>
                </g>
                <g>
                    <path
                        d="m330.759 360.265-4.684-20.8c-.614-2.726-3.157-4.455-5.952-4.455h-66.968c-2.794 0-5.338 1.73-5.951 4.455l-4.684 20.8-1.879 14.87 93.249 1.975z"
                        fill="#8d9ca8"></path>
                    <path
                        d="m330.759 360.265-4.684-20.8c-.614-2.726-3.157-4.455-5.952-4.455h-10c2.794 0 5.338 1.729 5.952 4.455l4.684 20.8 3.091 16.632 10.04.213z"
                        fill="#8192a5"></path>
                    <path
                        d="m236.606 360.265h100.066c3.779 0 6.87 3.091 6.87 6.87v11.515c0 3.779-3.091 6.871-6.87 6.871h-100.066c-3.779 0-6.87-3.091-6.87-6.871v-11.515c.001-3.778 3.091-6.87 6.87-6.87z"
                        fill="#9facba"></path>
                    <path
                        d="m236.606 385.52h100.066c3.779 0 6.87-3.092 6.87-6.87v-11.515c0-3.779-3.091-6.871-6.87-6.871h-3.13v8.385c0 3.779-3.091 6.87-6.87 6.87h-96.935v3.13c0 3.78 3.09 6.871 6.869 6.871z"
                        fill="#8d9ca8"></path>
                </g>
                <g>
                    <path
                        d="m346.574 242.037-17.162 17.162 101.922 106.932c1.687 1.769 3.73 2.733 6.075 2.863 2.346.131 4.339-.607 5.925-2.194l10.841-10.841c1.586-1.587 2.324-3.58 2.194-5.925-.131-2.345-1.094-4.389-2.863-6.075z"
                        fill="#e3a76f"></path>
                    <path
                        d="m346.574 242.037-5.074 5.074 106.933 101.923c1.769 1.686 2.732 3.73 2.863 6.076.131 2.345-.607 4.338-2.194 5.925l5.074-5.074c1.586-1.587 2.324-3.58 2.194-5.925-.131-2.345-1.094-4.389-2.863-6.075z"
                        fill="#c48958"></path>
                    <path
                        d="m352.571 247.827c6.199-5.266 13.713-10.972 24.185-18.764l-36.598-36.598c-23.906 32.134-28.185 36.412-60.319 60.318l36.598 36.598c7.792-10.472 13.498-17.986 18.764-24.185 5.626-6.622 10.748-11.744 17.37-17.369z"
                        fill="#c48958"></path>
                    <path
                        d="m345.233 197.54-5.074-5.074c-23.906 32.134-28.185 36.412-60.319 60.318l5.074 5.074c32.134-23.907 36.412-28.185 60.319-60.318z"
                        fill="#ab7448"></path>
                    <path
                        d="m341.851 176.669-8.674 8.673c-2.385 2.385-2.385 6.287-.001 8.672l42.032 42.031c2.385 2.385 6.287 2.385 8.672 0l8.674-8.674c2.385-2.385 2.385-6.287 0-8.672l-42.032-42.031c-2.384-2.384-6.287-2.384-8.671.001z"
                        fill="#e3a76f"></path>
                    <path
                        d="m341.851 176.669-5.074 5.074c2.385-2.385 6.287-2.385 8.672 0l42.031 42.031c2.385 2.385 2.385 6.287 0 8.672l5.074-5.074c2.385-2.385 2.385-6.287 0-8.672l-42.032-42.031c-2.384-2.385-6.287-2.385-8.671 0z"
                        fill="#c48958"></path>
                    <path
                        d="m272.717 245.802-8.674 8.674c-2.384 2.384-2.385 6.287 0 8.672l42.032 42.032c2.385 2.384 6.287 2.384 8.672-.001l8.674-8.673c2.385-2.385 2.385-6.287 0-8.672l-42.032-42.032c-2.385-2.385-6.287-2.385-8.672 0z"
                        fill="#e3a76f"></path>
                    <path
                        d="m314.746 305.179 5.074-5.074c-2.385 2.385-6.287 2.385-8.672 0l-42.031-42.031c-2.385-2.385-2.385-6.287 0-8.672l-5.074 5.074c-2.385 2.385-2.385 6.287 0 8.672l42.032 42.031c2.385 2.385 6.287 2.385 8.671 0z"
                        fill="#c48958"></path>
                </g>
            </g>
        </svg>


    )
}