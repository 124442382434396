// eslint-disable-next-line react/require-default-props
export default function AnalysisIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g id="_x37__x2C__Coin_x2C__contact_x2C__email_x2C__envelope_x2C__letter">
                <g id="XMLID_244_">
                    <path id="XMLID_294_"
                          d="m460.813 393.302v27.929h-22.67c-.85 4.655-2.595 11.091-4.247 15.617l19.748 11.277-13.44 23.192-19.726-11.451c-3.118 3.7-7.937 8.553-11.648 11.638l11.427 19.736-23.262 13.418-11.241-19.772c-4.563 1.688-11.056 3.434-15.711 4.271v22.843h-26.765v-22.843c-4.655-.851-11.544-2.583-16.105-4.235l-11.521 19.724-23.31-13.417 11.381-19.749c-3.712-3.118-8.541-7.948-11.625-11.637l-19.76 11.405-12.789-22.123-.664-1.141 19.761-11.137c-1.652-4.526-3.398-10.962-4.26-15.617h-23.041v-27.929h23.041c.861-4.655 2.607-11.078 4.26-15.605l-19.761-11.265 13.442-23.192 19.747 11.438c3.083-3.712 7.902-8.507 11.613-11.625l-11.404-19.736 23.264-13.43 11.626 19.748c4.562-1.63 11.45-3.398 16.105-4.235v-22.867h26.765v22.867c4.655.837 11.148 2.605 15.711 4.235l11.253-19.621.069-.127 23.216 13.43-11.439 19.759c3.713 3.107 8.519 7.926 11.638 11.638l19.725-11.404 13.43 23.263-19.748 11.125c1.652 4.527 3.397 10.95 4.247 15.605zm-44.152 13.953c0-33.33-27.057-60.351-60.396-60.351-33.329 0-60.386 27.021-60.386 60.351 0 33.375 27.057 60.396 60.386 60.396 33.339-.001 60.396-27.021 60.396-60.396z"
                          fill="#a9b0bb"></path>
                    <path id="XMLID_293_"
                          d="m356.265 346.904c33.34 0 60.396 27.021 60.396 60.351 0 33.375-27.057 60.396-60.396 60.396-33.329 0-60.386-27.021-60.386-60.396 0-33.33 27.057-60.351 60.386-60.351z"
                          fill="#636c77"></path>
                    <path id="XMLID_288_"
                          d="m128.666 59.315-.384-.478 11.799-9.589 18.853 23.204 10.416 12.824 106.467 131.094 36.331 15.769-8.692-38.217-106.469-131.093-2.77-3.421.104-.093h129.544l72.942 71.079v179.504l.198.115-11.253 19.621c-4.563-1.63-11.056-3.398-15.711-4.235v-22.867h-26.765v22.867c-4.655.837-11.544 2.605-16.105 4.235l-11.626-19.748-23.264 13.43 11.404 19.736c-3.711 3.118-8.53 7.913-11.613 11.625l-19.747-11.438-13.442 23.192 19.761 11.265c-1.652 4.527-3.398 10.95-4.26 15.605h-23.041v27.929h23.041c.861 4.655 2.607 11.091 4.26 15.617l-19.761 11.137.664 1.141-.059.035h-155.935v-389.845zm194.829 70.987h71.393l-71.393-70.905z"
                          fill="#f4f6f8"></path>
                    <path id="XMLID_287_" d="m394.888 130.302h-71.393v-70.906z" fill="#e4e8eb"></path>
                    <path id="XMLID_286_"
                          d="m359.419 12.371c8.053 0 14.627 6.598 14.627 14.686 0 8.054-6.574 14.64-14.627 14.64s-14.664-6.586-14.664-14.64c0-8.088 6.611-14.686 14.664-14.686z"
                          fill="#a9b0bb"></path>
                    <path id="XMLID_285_" d="m151.962 121.796h168.881v35.606h-168.881z" fill="#5e9cea"
                          transform="matrix(-.63 -.776 .776 -.63 277.076 411.114)"></path>
                    <path id="XMLID_280_" d="m194.218 59.408 2.769 3.421-27.637 22.448-10.416-12.824 27.649-22.448z"
                          fill="#4a88da"></path>
                    <path id="XMLID_279_"
                          d="m186.583 50.005-27.649 22.448-18.841-23.204h-.012l-9.31-11.463 7.74-6.296 1-.814 11.208-9.066-.025-.022 7.716-6.274z"
                          fill="#5e9cea"></path>
                    <path id="XMLID_278_" d="m133.157 0 17.537 21.588-11.183 9.088-1 .814-17.527-21.575z"
                          fill="#4a88da"></path>
                    <path id="XMLID_277_"
                          d="m245.526 481.744h-167.575c-3.214 0-5.818-2.605-5.818-5.818v-391.009c0-3.214 2.604-5.818 5.818-5.818s5.818 2.604 5.818 5.818v385.189h161.757c3.213 0 5.818 2.605 5.818 5.819.001 3.214-2.605 5.819-5.818 5.819z"
                          fill="#cad0d7"></path>
                    <path id="XMLID_276_"
                          d="m172.879 119.607c-1.693 0-3.372-.735-4.521-2.152l-44.609-54.97c-2.009-2.495-1.622-6.144.863-8.163l11.8-9.589c1.037-.843 2.333-1.303 3.669-1.303 3.214 0 5.824 2.605 5.824 5.818 0 1.924-.931 3.63-2.368 4.689l-7.075 5.749 40.931 50.435c2.025 2.495 1.645 6.159-.851 8.185-1.077.876-2.374 1.301-3.663 1.301z"
                          fill="#5e9cea"></path>
                    <path id="XMLID_256_"
                          d="m311.857 238.527h-180.376c-3.213 0-5.818-2.605-5.818-5.818 0-3.214 2.605-5.818 5.818-5.818h180.376c3.214 0 5.819 2.604 5.819 5.818.001 3.213-2.605 5.818-5.819 5.818z"
                          fill="#cad0d7"></path>
                    <path id="XMLID_255_"
                          d="m366.552 271.111h-231.578c-3.215 0-5.819-2.604-5.819-5.818s2.604-5.818 5.819-5.818h231.578c3.215 0 5.819 2.604 5.819 5.818s-2.604 5.818-5.819 5.818z"
                          fill="#cad0d7"></path>
                    <path id="XMLID_254_"
                          d="m376.324 416.891c-.541-3.882-3.339-11.065-15.993-15.561-8.959-3.187-11.412-7.167-11.195-8.596.162-1.067 2.465-2.706 6.867-2.96.025.001.05.008.076.008.168 0 .326-.036.49-.05 3.016-.123 6.61.438 10.409 1.636 3.069.969 6.333-.735 7.299-3.799.966-3.065-.683-6.333-3.747-7.3-2.972-.938-6.53-1.564-8.53-1.9v-7.178c0-3.214-2.785-5.819-6-5.819-3.213 0-6 2.605-6 5.819v7.711c-7 1.734-11.6 6.153-12.5 12.088-1.016 6.706 3.455 15.824 18.869 21.306 5.824 2.068 8.179 4.634 8.396 6.2.162 1.148-.743 2.585-2.383 3.752-3.41 2.425-10.127 3.855-17.86-.101-2.857-1.458-6.361-.328-7.826 2.532-1.463 2.861-.458 6.366 2.404 7.83 3.563 1.82 7.899 2.962 10.899 3.44v7.391c0 3.214 2.787 5.819 6 5.819 3.215 0 6-2.605 6-5.819v-8.18c2-.747 5.023-1.886 7.196-3.431 5.269-3.747 7.903-9.293 7.129-14.838z"
                          fill="#fff"></path>
                    <g fill="#5e9cea">
                        <path id="XMLID_253_"
                              d="m77.381 50.89c-1.489 0-2.979-.568-4.114-1.704l-29.289-29.278c-2.273-2.271-2.274-5.955-.004-8.229 2.273-2.273 5.956-2.273 8.229-.004l29.291 29.28c2.273 2.272 2.273 5.957 0 8.229-1.135 1.137-2.625 1.706-4.113 1.706z"></path>
                        <path id="XMLID_252_"
                              d="m48.09 50.89c-1.489 0-2.979-.569-4.116-1.706-2.271-2.273-2.27-5.957.004-8.229l29.289-29.277c2.271-2.271 5.957-2.271 8.228 0 2.273 2.272 2.273 5.957 0 8.229l-29.292 29.281c-1.137 1.134-2.624 1.702-4.113 1.702z"></path>
                        <path id="XMLID_251_"
                              d="m463.909 313.656c-1.489 0-2.978-.568-4.114-1.703l-29.281-29.293c-2.272-2.273-2.27-5.957.003-8.229 2.273-2.271 5.956-2.271 8.229.004l29.276 29.288c2.273 2.273 2.273 5.957.001 8.229-1.135 1.136-2.626 1.704-4.114 1.704z"></path>
                        <path id="XMLID_250_"
                              d="m434.63 313.656c-1.489 0-2.979-.568-4.115-1.704-2.271-2.272-2.271-5.956.001-8.229l29.276-29.288c2.273-2.274 5.957-2.274 8.229-.004 2.272 2.272 2.275 5.956.003 8.229l-29.281 29.293c-1.135 1.135-2.625 1.703-4.113 1.703z"></path>
                        <path id="XMLID_249_"
                              d="m427.065 75.607h-30.257c-3.214 0-5.818-2.605-5.818-5.819 0-3.213 2.604-5.818 5.818-5.818h30.257c3.214 0 5.818 2.605 5.818 5.818.001 3.214-2.604 5.819-5.818 5.819z"></path>
                        <path id="XMLID_248_"
                              d="m411.937 90.735c-3.213 0-5.818-2.605-5.818-5.818v-29.093c0-3.214 2.605-5.819 5.818-5.819 3.215 0 5.819 2.605 5.819 5.819v29.093c0 3.213-2.605 5.818-5.819 5.818z"></path>
                    </g>
                    <path id="XMLID_247_" d="m303.456 193.922 8.692 38.217-36.331-15.769z" fill="#636c77"></path>
                </g>
            </g>
        </svg>


    )
}