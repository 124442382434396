import WhatsAppIcon from "../assets/icons/socials/WhatsAppIcon";
import InstagramIcon from "../assets/icons/socials/InstagramIcon";

export const socialsData = [{
    id: 1,
    icon: <WhatsAppIcon/>,
    link: "https://wa.me/77073084803?text=",
},
    {
        id: 2,
        icon: <InstagramIcon/>,
        link: "https://www.instagram.com/ddgrroup?igsh=OGQ5ZDc2ODk2ZA==",
    },
];