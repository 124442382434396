// eslint-disable-next-line react/require-default-props
export default function ExecutiveDevelopmentIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
            <g id="Page-1" fill="none" fill-rule="evenodd">
                <g id="091---Payment-Security-Warning" fill-rule="nonzero">
                    <path id="Shape"
                          d="m53 8v8h-4v-8c0-2.209139-1.790861-4-4-4s-4 1.790861-4 4v8h-4v-8c0-4.418278 3.581722-8 8-8s8 3.581722 8 8z"
                          fill="#d1d4d1"></path>
                    <path id="Shape"
                          d="m55 16v4h-20v-4c.0032948-1.1032019.8967981-1.9967052 2-2h16c1.1032019.0032948 1.9967052.8967981 2 2z"
                          fill="#7f8c8d"></path>
                    <path id="Shape"
                          d="m50 31.7v22.3c0 2.209139-1.790861 4-4 4h-42c-2.209139 0-4-1.790861-4-4v-24c0-2.209139 1.790861-4 4-4h29z"
                          fill="#3b97d3"></path>
                    <rect id="Rectangle-path" fill="#f3d55b" height="6" rx="2" width="8" x="5" y="31"></rect>
                    <path id="Shape"
                          d="m11 38h-4c-1.65685425 0-3-1.3431458-3-3v-2c0-1.6568542 1.34314575-3 3-3h4c1.6568542 0 3 1.3431458 3 3v2c0 1.6568542-1.3431458 3-3 3zm-4-6c-.55228475 0-1 .4477153-1 1v2c0 .5522847.44771525 1 1 1h4c.5522847 0 1-.4477153 1-1v-2c0-.5522847-.4477153-1-1-1z"
                          fill="#2980ba"></path>
                    <g fill="#ecf0f1">
                        <path id="Shape"
                              d="m9 43h-4c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h4c.55228475 0 1 .4477153 1 1s-.44771525 1-1 1z"></path>
                        <path id="Shape"
                              d="m17 43h-4c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h4c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z"></path>
                        <path id="Shape"
                              d="m25 43h-4c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h4c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z"></path>
                        <path id="Shape"
                              d="m12 49h-3c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h3c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z"></path>
                        <path id="Shape"
                              d="m12 53h-7c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h7c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z"></path>
                    </g>
                    <path id="Shape"
                          d="m45 54h-6c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h5v-4c0-.5522847.4477153-1 1-1s1 .4477153 1 1v5c0 .5522847-.4477153 1-1 1z"
                          fill="#2980ba"></path>
                    <path id="Shape"
                          d="m58 20v20c-.0497884 1.1501953-1.0195688 2.0440022-2.17 2h-21.66c-1.1487989.0395877-2.1159447-.8517909-2.17-2v-20c.0540553-1.1482091 1.0212011-2.0395877 2.17-2h21.66c1.1504312-.0440022 2.1202116.8498047 2.17 2z"
                          fill="#f3d55b"></path>
                    <path id="Shape"
                          d="m52.09 38h-14.18c-.7043052-.0233538-1.3432457-.4189153-1.6781623-1.0389331-.3349167-.6200177-.3154372-1.3712397.0511623-1.9730669l7.089-12.041c.3329891-.5853361.9545758-.9468548 1.628-.9468548s1.2950109.3615187 1.628.9468548l7.089 12.041c.3665995.6018272.386079 1.3530492.0511623 1.9730669-.3349166.6200178-.9738571 1.0155793-1.6781623 1.0389331z"
                          fill="#e64c3c"></path>
                    <circle id="Oval" cx="45" cy="34" fill="#fff" r="1"></circle>
                    <path id="Shape"
                          d="m45 31c-.5522847 0-1-.4477153-1-1v-2c0-.5522847.4477153-1 1-1s1 .4477153 1 1v2c0 .5522847-.4477153 1-1 1z"
                          fill="#fff"></path>
                    <path id="Shape"
                          d="m52.09 39h-14.18c-1.0421124.0023437-2.0033128-.5613563-2.51-1.472-.5399785-.9456321-.533113-2.1078135.018-3.047l7.09-12.042c.5125669-.8903345 1.4616634-1.4390789 2.489-1.4390789s1.9764331.5487444 2.489 1.4390789l7.09 12.041c.5510848.9395528.5579496 2.1020041.018 3.048-.5056444.9087634-1.464035 1.4721624-2.504 1.472zm-7.09-16c-.3189769.0014451-.612138.1755812-.766.455l-7.089 12.045c-.1908191.3194657-.1931168.7173518-.006 1.039.1544331.2820657.4494348.4584546.771.461h14.18c.3224133-.0033474.6176175-.1813888.771-.465.1870809-.3220148.1847839-.7201652-.006-1.04l-7.089-12.04c-.153862-.2794188-.4470231-.4535549-.766-.455z"
                          fill="#fff"></path>
                </g>
            </g>
        </svg>

    )
}