// eslint-disable-next-line react/require-default-props
export default function CreditDisputesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-17 1 511 511.99925">
            <path
                d="m426.8125 187.617188-238.695312 238.699218c-10.789063 10.785156-28.277344 10.785156-39.0625 0l-140.464844-140.464844c-10.785156-10.789062-10.785156-28.277343 0-39.0625l238.695312-238.699218c10.789063-10.785156 28.277344-10.785156 39.0625 0l140.464844 140.464844c10.789062 10.785156 10.789062 28.277343 0 39.0625zm0 0"
                fill="#8cbafa"></path>
            <path
                d="m426.8125 148.554688-17.527344-17.53125c9.605469 9.605468 8.644532 26.136718-2.140625 36.921874l-238.699219 238.699219c-10.785156 10.789063-27.316406 11.746094-36.921874 2.140625l17.53125 17.53125c10.785156 10.785156 28.273437 10.785156 39.0625 0l238.695312-238.699218c10.789062-10.785157 10.789062-28.277344 0-39.0625zm0 0"
                fill="#5692d8"></path>
            <path
                d="m54.488281 276.027344c-1.921875 0-3.839843-.734375-5.304687-2.199219-2.929688-2.929687-2.929688-7.675781 0-10.605469l116.917968-116.917968c2.929688-2.929688 7.679688-2.929688 10.605469 0 2.929688 2.929687 2.929688 7.679687 0 10.605468l-116.917969 116.921875c-1.464843 1.460938-3.382812 2.195313-5.300781 2.195313zm0 0"
                fill="#252d4c"></path>
            <path
                d="m91.644531 313.183594c-1.921875 0-3.839843-.734375-5.304687-2.199219-2.929688-2.929687-2.929688-7.675781 0-10.605469l116.917968-116.917968c2.929688-2.929688 7.679688-2.929688 10.605469 0 2.929688 2.929687 2.929688 7.679687 0 10.605468l-116.917969 116.917969c-1.464843 1.464844-3.382812 2.199219-5.300781 2.199219zm0 0"
                fill="#252d4c"></path>
            <path
                d="m243.386719 62.496094-23.390625 23.386718c-9.195313 9.195313-9.195313 24.101563 0 33.296876 9.195312 9.195312 24.105468 9.195312 33.300781 0l23.386719-23.386719c9.195312-9.195313 9.195312-24.101563 0-33.296875-9.195313-9.195313-24.105469-9.195313-33.296875 0zm0 0"
                fill="#ffcd34"></path>
            <path
                d="m449.351562 258.109375h-337.570312c-15.253906 0-27.621094 12.367187-27.621094 27.621094v198.648437c0 15.253906 12.367188 27.621094 27.621094 27.621094h337.570312c15.257813 0 27.621094-12.367188 27.621094-27.621094v-198.648437c.003906-15.253907-12.363281-27.621094-27.621094-27.621094zm0 0"
                fill="#ffcd34"></path>
            <path
                d="m449.351562 258.109375h-24.722656c15.253906 0 27.621094 12.367187 27.621094 27.621094v198.648437c0 15.253906-12.367188 27.621094-27.621094 27.621094h24.722656c15.257813 0 27.621094-12.367188 27.621094-27.621094v-198.648437c.003906-15.253907-12.363281-27.621094-27.621094-27.621094zm0 0"
                fill="#e69012"></path>
            <path d="m84.160156 320.515625h392.816406v47.722656h-392.816406zm0 0" fill="#575b7a"></path>
            <path d="m452.25 320.515625h24.722656v47.722656h-24.722656zm0 0" fill="#252d4c"></path>
            <path
                d="m380.429688 444.492188v29.433593c0 2.277344 1.84375 4.121094 4.121093 4.121094h46.816407c2.277343 0 4.121093-1.84375 4.121093-4.121094v-29.433593c0-2.277344-1.84375-4.121094-4.121093-4.121094h-46.816407c-2.277343 0-4.121093 1.84375-4.121093 4.121094zm0 0"
                fill="#e9e9ea"></path>
            <path
                d="m239.273438 444.492188v29.433593c0 2.277344 1.847656 4.121094 4.121093 4.121094h99.023438c2.277343 0 4.121093-1.84375 4.121093-4.121094v-29.433593c0-2.277344-1.84375-4.121094-4.121093-4.121094h-99.023438c-2.273437 0-4.121093 1.84375-4.121093 4.121094zm0 0"
                fill="#e9e9ea"></path>
            <path
                d="m198.910156 485.546875h-49.332031c-4.144531 0-7.5-3.359375-7.5-7.5 0-4.144531 3.355469-7.5 7.5-7.5h49.332031c4.144532 0 7.5 3.355469 7.5 7.5 0 4.140625-3.355468 7.5-7.5 7.5zm0 0"
                fill="#252d4c"></path>
        </svg>

    )
}