// eslint-disable-next-line react/require-default-props
export default function ComplaintIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1_1_" viewBox="0 0 64 64">
            <path d="m28 37h13v17h-13z" fill="#adc4e6"></path>
            <g>
                <path
                    d="m22.03 48.68-4.19-1.63c-1.2-.69-2.57-1.05-3.96-1.05h-6.88c-2.21 0-4 1.79-4 4v12h14v-10l4.75 1.58c.16.06.34.09.52.1l5.73-1.99v-4.45z"
                    fill="#759acc"></path>
            </g>
            <g>
                <path d="m10 46 2 10-2 3-2-3z" fill="#eaeaea"></path>
            </g>
            <g>
                <path
                    d="m15 41c0 .21-.01.42-.05.62-.29 2.47-2.4 4.38-4.95 4.38s-4.66-1.91-4.95-4.38c-.04-.2-.05-.41-.05-.62 0-2.76 2.24-5 5-5s5 2.24 5 5z"
                    fill="#ffdbac"></path>
            </g>
            <g>
                <path
                    d="m10 36c-2.76 0-5 2.24-5 5 0 .21.01.42.05.62 1.07-1.58 2.89-2.62 4.95-2.62s3.88 1.04 4.95 2.62c.04-.2.05-.41.05-.62 0-2.76-2.24-5-5-5z"
                    fill="#526273"></path>
            </g>
            <circle cx="34" cy="17" fill="#526273" r="15"></circle>
            <g>
                <path d="m30 40h2v2h-2z" fill="#557196"></path>
            </g>
            <g>
                <path d="m34 40h5v2h-5z" fill="#557196"></path>
            </g>
            <g>
                <path d="m30 44h2v2h-2z" fill="#557196"></path>
            </g>
            <g>
                <path d="m34 44h5v2h-5z" fill="#557196"></path>
            </g>
            <g>
                <path d="m30 48h2v2h-2z" fill="#557196"></path>
            </g>
            <g>
                <path d="m34 48h5v2h-5z" fill="#557196"></path>
            </g>
            <g>
                <path
                    d="m7 33.242-.738-2.952-1.976.658c-.267.09-.553.061-.798-.071-.244-.135-.421-.364-.488-.635l-1-4 1.939-.484.738 2.952 1.976-.658c.265-.091.553-.062.798.071.244.135.421.364.488.635l1 4z"
                    fill="#526273"></path>
            </g>
            <g>
                <path
                    d="m12.97 32h-2v-5.612l-1.684.561c-.306.103-.64.051-.901-.137-.261-.189-.415-.491-.415-.812v-4h2v2.612l1.684-.561c.304-.104.639-.052.901.137.261.188.415.49.415.812z"
                    fill="#526273"></path>
            </g>
            <g>
                <g>
                    <path d="m58 48h-6c-2.209 0-4 1.791-4 4v10h14v-10c0-2.209-1.791-4-4-4z" fill="#759acc"></path>
                </g>
            </g>
            <g>
                <g>
                    <path d="m55 48-2 8 2 3 2-3z" fill="#eaeaea"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="m60 43c0 .21-.01.42-.05.62-.29 2.47-2.4 4.38-4.95 4.38s-4.66-1.91-4.95-4.38c-.04-.2-.05-.41-.05-.62 0-2.76 2.24-5 5-5s5 2.24 5 5z"
                        fill="#ffdbac"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="m55 38c-2.76 0-5 2.24-5 5 0 .21.01.42.05.62 1.07-1.58 2.89-2.62 4.95-2.62s3.88 1.04 4.95 2.62c.04-.2.05-.41.05-.62 0-2.76-2.24-5-5-5z"
                        fill="#526273"></path>
                </g>
            </g>
            <g fill="#eaeaea">
                <path d="m41.468 9.824h-.936l-3 8 .936.352 2.532-6.753 2.532 6.753.936-.352z"></path>
                <path d="m27.468 9.824h-.936l-3 8 .936.352 2.532-6.753 2.532 6.753.936-.352z"></path>
            </g>
            <path d="m43 9h-8v-2h-2v2h-8v1h8v16h2v-16h8z" fill="#759acc"></path>
            <g>
                <path d="m23 16c0 2.209 1.791 4 4 4s4-1.791 4-4z" fill="#adc4e6"></path>
            </g>
            <g>
                <path d="m37 16c0 2.209 1.791 4 4 4s4-1.791 4-4z" fill="#adc4e6"></path>
            </g>
            <g>
                <path d="m29 25h10v3h-10z" fill="#6f93c2"></path>
            </g>
        </svg>

    )
}