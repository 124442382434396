// eslint-disable-next-line react/require-default-props
export default function CustomersProtectIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" viewBox="0 0 510.023 510.023">
            <g>
                <g>
                    <path
                        d="m197.305 60c3.494 20.965 6.506 39.037 10 60-13.845 0-25.741 0-39.586 0l-17.707 15 22.707 15h82.293l15-15-15-15c-6.049 0-11.245 0-17.293 0-3.494-20.963-6.506-39.035-10-60h27.293l15-15-15-15c-27.815 0-86.058 0-102.293 0l-17.707 15 22.707 15z"
                        fill="#cde8ff"></path>
                    <path
                        d="m403.231 244.59c16.865-16.864 45.521-5.529 46.78 17.93.03-.156 29.98-154.846 30-154.948 10.881-3.847 18.275-2.148 30-2.571v246.213l-90 83.787-75 15-75-15v-55.623z"
                        fill="#fcb0a2"></path>
                    <path
                        d="m442.071 283.32c5.551-5.551 8.2-12.91 7.94-20.18 0-26.732 0-90.227 0-113.141 0-19.592 12.524-36.25 30-42.429v159.79c0 13.18-5.13 25.58-14.46 34.898l-74.13 74.141-21.22-21.209z"
                        fill="#f98c82"></path>
                    <path d="m420.012 510.01h-75-75v-22.504-22.506l75-15 75 15v22.506z" fill="#353d77"></path>
                    <path
                        d="m106.791 244.613c-16.864-16.865-45.52-5.529-46.779 17.93-.03-.156-29.981-154.848-30-154.949-10.881-3.848-18.275-2.148-30-2.57v246.213l90 83.787 75 14.977 75-14.977v-55.625z"
                        fill="#fdd0bb"></path>
                    <path
                        d="m67.951 283.342c-5.549-5.549-8.199-12.908-7.939-20.18 0-26.731 0-90.227 0-113.139 0-19.592-12.524-36.252-30-42.43v159.789c0 13.18 5.13 25.58 14.46 34.9l74.13 74.139 21.221-21.209z"
                        fill="#fcb0a2"></path>
                    <path d="m90.012 510.023h75 75v-22.505-22.504l-75-15.014-75 15.014v22.504z" fill="#454c95"></path>
                    <path d="m270.012 435.01h150v29.99h-150z" fill="#cde8ff"></path>
                    <path d="m90.012 435h150v29.992h-150z" fill="#e9f4ff"
                          transform="matrix(-1 0 0 -1 330.023 899.992)"></path>
                    <path
                        d="m210.012 195c-5.246 5.115-9.754 9.51-15 14.625v30.375h30c0-10.492 0-19.508 0-30-5.246-5.246-9.754-9.754-15-15z"
                        fill="#353d77"></path>
                    <path d="m315.012 210v30h30c0-10.492 0-19.508 0-30l-15-15z" fill="#262f60"></path>
                    <path
                        d="m173.801 156.491c-.024-.136-26.066-156.391-26.082-156.491-19.686 0-61.083 0-72.707 0v30h47.293c2.663 15.98 19.234 115.407 21.904 131.424 4.691 28.146 28.807 48.576 57.344 48.576h53.459l15-15-15-15c-17.003 0-36.456 0-53.459 0-13.811 0-25.483-9.887-27.752-23.509z"
                        fill="#454c95"></path>
                    <path d="m360.012 210c0-10.492 0-19.508 0-30-28.278 0-88.527 0-105 0v30z" fill="#353d77"></path>
                    <path
                        d="m257.305 60c3.494 20.965 6.506 39.037 10 60-4.299 0-7.993 0-12.293 0v30h105c0-14.518 0-105.386 0-120-28.278 0-88.527 0-105 0v30c.105 0 2.434 0 2.293 0zm72.707 0v60c-11.294 0-20.999 0-32.293 0l-5-30-5-30z"
                        fill="#a3dbff"></path>
                </g>
            </g>
        </svg>

    )
}