// eslint-disable-next-line react/require-default-props
export default function FamilyDisputesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" viewBox="0 0 512 512">
            <g>
                <path
                    d="m380.832 481.341h-243.738c-5.879 0-11.516-2.338-15.669-6.498l-53.681-53.768c-4.145-4.151-6.473-9.778-6.473-15.644v-351.903c0-2.446 1.983-4.428 4.428-4.428h315.132c2.446 0 4.428 1.983 4.428 4.428v423.385c.001 2.446-1.982 4.428-4.427 4.428z"
                    fill="#aee0f4"></path>
                <path
                    d="m215.463 22.856c0-12.9-10.687-23.304-23.69-22.842-12.386.44-22.023 10.996-22.023 23.389v14.707h45.713z"
                    fill="#fbc96d"></path>
                <path
                    d="m343.532 89.887 6.854 412.896c0 2.452-1.978 4.43-4.43 4.43l-33.82 4.787h-190.324c-2.977 0-5.882-.639-8.54-1.854l-4.432-69.659c0-2.277-1.844-4.121-4.121-4.121l-72.255-7.131c-1.216-2.647-1.854-5.563-1.854-8.53v-336.519c0-2.442 1.988-4.43 4.43-4.43h295.868l8.195 5.7c2.451.001 4.429 1.989 4.429 4.431z"
                    fill="#dff8ff"></path>
                <path
                    d="m264.198 96.937h-143.182c-2.276 0-4.121-1.845-4.121-4.121v-46.757c0-8.534 6.918-15.453 15.453-15.453h120.518c8.534 0 15.453 6.918 15.453 15.453v46.758c0 2.276-1.845 4.12-4.121 4.12z"
                    fill="#ffda8f"></path>
                <path
                    d="m350.172 79.757h-19.264v129.226h-11.229v61.43c0 6.436 2.047 12.397 5.519 17.273 1.394 1.957 2.088 4.325 1.923 6.722l-7.318 106.457c-.791 11.522 2.496 22.983 9.136 32.302 1.261 1.77 1.97 3.873 1.97 6.046v50.062c0 12.548-10.178 22.726-22.726 22.726h41.99c2.446 0 4.43-1.983 4.43-4.43v-55.815-242.773-124.797c-.001-2.441-1.979-4.429-4.431-4.429z"
                    fill="#c0ecfc"></path>
                <path
                    d="m262.205 352.003-65.952 65.963c-1.01 1.01-2.328 1.504-3.647 1.504l-7.153-21.838c0-.227.041-.443.103-.649l9.879-29.628c.206-.608.113-1.277-.247-1.803l-17.488-28.422c-.361-.525-.443-1.195-.247-1.803l15.884-32.361c1.061-.144 2.091-.629 2.915-1.453l7.726-7.737c8.046-8.035 18.574-12.053 29.113-12.053s21.077 4.018 29.113 12.053c16.082 16.082 16.082 42.156.001 58.227z"
                    fill="#fe646f"></path>
                <path
                    d="m202.588 367.6-9.879 29.628c-.062.206-.103.422-.103.649v21.593c-1.319 0-2.627-.494-3.637-1.504l-65.963-65.963c-8.046-8.035-12.063-18.574-12.063-29.113s4.018-21.067 12.063-29.113c16.081-16.071 42.145-16.071 58.226 0l7.737 7.737c1.195 1.195 2.823 1.679 4.368 1.453l-10.714 32.131c-.196.608-.113 1.277.247 1.803l19.47 28.897c.361.524.454 1.194.248 1.802z"
                    fill="#fd8087"></path>
                <g>
                    <g>
                        <path
                            d="m172.003 223.38h-75.155c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h75.155c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
                            fill="#c0ecfc"></path>
                    </g>
                    <g>
                        <path
                            d="m288.366 223.38h-75.156c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h75.156c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
                            fill="#c0ecfc"></path>
                    </g>
                    <g>
                        <path
                            d="m323.943 167.651h-252.371c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h252.37c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.725 7.726z"
                            fill="#c0ecfc"></path>
                    </g>
                    <g>
                        <path
                            d="m312.082 479.233h-98.872c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h98.871c4.268 0 7.726 3.459 7.726 7.726.001 4.267-3.458 7.726-7.725 7.726z"
                            fill="#c0ecfc"></path>
                    </g>
                    <g>
                        <path
                            d="m172.003 479.233h-58.734c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h58.734c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
                            fill="#c0ecfc"></path>
                    </g>
                </g>
                <g>
                    <path
                        d="m349.887 278.656 19.549-6.101 16.655 6.101 6.908 118.539c1.117 16.247-5.166 34.032-21.452 34.032l-2.115 1.167c-16.286 0-29.165-13.794-28.048-30.042z"
                        fill="#d37f66"></path>
                    <path
                        d="m369.436 432.398v-153.744l9.116-6.098 10.426 6.098 8.499 123.694c1.124 16.256-11.754 30.05-28.041 30.05z"
                        fill="#c17467"></path>
                    <path
                        d="m395.423 267.514-.97-36.901-21.913-4.791-31.224 4.791v39.802c0 4.552 3.69 8.241 8.241 8.241h19.878l17.745-2.901c4.553 0 8.243-3.69 8.243-8.241z"
                        fill="#fbc96d"></path>
                    <path d="m397.55 230.616v39.796c0 4.553-3.688 8.241-8.241 8.241h-19.872v-48.037l15.321-8.091z"
                          fill="#fcbc55"></path>
                    <path d="m450.752 225.822-35.55 2.23-5.623 2.561h-121.466v-85.647h130.7l31.939 7.362z"
                          fill="#d37f66"></path>
                    <path d="m450.748 144.967v85.649h-47.996c8.87 0 16.061-7.201 16.061-16.071v-69.578z"
                          fill="#c17467"></path>
                    <g fill="#dd8d6b">
                        <path
                            d="m477.27 249.144h-27.53c-5.69 0-10.302-4.612-10.302-10.302v-102.105c0-5.69 4.612-10.302 10.302-10.302h27.529c2.276 0 4.121 1.845 4.121 4.121v114.467c0 2.276-1.845 4.121-4.12 4.121z"></path>
                        <path
                            d="m261.595 126.435h27.529c5.69 0 10.302 4.612 10.302 10.302v102.105c0 5.69-4.612 10.302-10.302 10.302h-27.529c-2.276 0-4.121-1.845-4.121-4.121v-114.467c.001-2.276 1.846-4.121 4.121-4.121z"></path>
                    </g>
                </g>
                <path
                    d="m113.271 433.356v76.79c-2.235-1.01-4.275-2.431-6.037-4.193l-70.598-70.681c-1.751-1.762-3.163-3.801-4.172-6.037h76.687c2.276 0 4.12 1.844 4.12 4.121z"
                    fill="#aee0f4"></path>
            </g>
        </svg>


    )
}