export const navigationList = [{
    id: 1,
    name: "Плюсы",
    link: "#advantage"
},
    {
        id: 2,
        name: "Услуги",
        link: "#services"
    },
    {
        id: 3,
        name: "Отзывы",
        link: "#reviews"
    },
    {
        id: 4,
        name: "Связаться",
        link: "#footer"
    }
]