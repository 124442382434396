// eslint-disable-next-line react/require-default-props
export default function OweProtectionIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g id="FLAT">
                <path d="m240 16h16v40h-16z" fill="#6e6e6e"></path>
                <rect fill="#6bad55" height="216" rx="16" width="368" x="96" y="136"></rect>
                <path
                    d="m431 192a23 23 0 0 1 -23-23v-1h-256v1a23 23 0 0 1 -23 23h-1v112h1a22.99988 22.99988 0 0 1 23 23v1h256v-1a22.99988 22.99988 0 0 1 23-23h1v-112z"
                    fill="#4a7f38"></path>
                <path d="m283.029 212h33.941v16h-33.941z" fill="#8c8c8c"
                      transform="matrix(.707 -.707 .707 .707 -67.696 276.569)"></path>
                <g fill="#dfdfdf">
                    <path d="m328 236.686h16v22.627h-16z"
                          transform="matrix(.707 -.707 .707 .707 -76.95 310.225)"></path>
                    <path d="m324.686 176h22.627v16h-22.627z"
                          transform="matrix(.707 -.707 .707 .707 -31.696 291.48)"></path>
                    <path d="m336 208h24v16h-24z"></path>
                    <path d="m264 172.686h16v22.627h-16z"
                          transform="matrix(.707 -.707 .707 .707 -50.441 246.225)"></path>
                    <path d="m296 160h16v24h-16z"></path>
                </g>
                <path d="m216 32-48 24v80h96v-80z" fill="#8c8c8c"></path>
                <path d="m200 80h32v56h-32z" fill="#dfdfdf"></path>
                <path
                    d="m304 80 11.9769-29.94225a16 16 0 0 1 14.85563-10.05775h42.33494a16 16 0 0 1 14.85563 10.05775l11.9769 29.94225 3.31371 3.31371a16 16 0 0 1 4.68629 11.31371v9.37258a8 8 0 0 1 -8 8h-96a8 8 0 0 1 -8-8v-9.37258a16 16 0 0 1 4.68629-11.31371z"
                    fill="#6e6e6e"></path>
                <path d="m312 136a8 8 0 0 1 -8-8v-16h16v16a8 8 0 0 1 -8 8z" fill="#353535"></path>
                <path d="m392 136a8 8 0 0 1 -8-8v-16h16v16a8 8 0 0 1 -8 8z" fill="#353535"></path>
                <path d="m344 40h16v16h-16z" fill="#575757"></path>
                <path
                    d="m482.19409 360.99023a23.883 23.883 0 0 0 -28.77441-10.10864l-181.06116 67.05969a32.00014 32.00014 0 0 1 -30.31409-4.408l-50.04443-37.53328h72a24 24 0 0 0 0-48h-92.11768a48 48 0 0 0 -33.94116 14.05884l-11.88232 11.88232a48.00113 48.00113 0 0 1 -30.05884 13.90161l-72 .15723v104h110.30493a15.995 15.995 0 0 1 3.35254.35522l74.33691 15.9292a111.99941 111.99941 0 0 0 69.56495-7.4403l189.98523-85.79969a23.88314 23.88314 0 0 0 10.64953-34.0542z"
                    fill="#f9c7b2"></path>
                <rect fill="#575757" height="144" rx="16" width="48" x="48" y="344"></rect>
                <rect fill="#edb6a4" height="16" rx="8" width="40" x="152" y="368"></rect>
                <path d="m263.029 223.029h33.941v33.941h-33.941z" fill="#575757"
                      transform="matrix(.707 -.707 .707 .707 -87.696 268.284)"></path>
                <circle cx="240" cy="280" fill="#353535" r="48"></circle>
                <path d="m229.657 301.657-11.314-11.314 13.657-13.656v-20.687h16v27.313z" fill="#ffc043"></path>
                <path d="m176 304h-16a24 24 0 0 1 0-48h16a24 24 0 0 1 0 48zm-16-32a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16z"
                      fill="#353535"></path>
                <path d="m128 304h-16a24 24 0 0 1 0-48h16a24 24 0 0 1 0 48zm-16-32a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16z"
                      fill="#353535"></path>
                <path
                    d="m72 320a24.02687 24.02687 0 0 1 -24-24v-16a24 24 0 0 1 48 0v16a24.02687 24.02687 0 0 1 -24 24zm0-48a8.00917 8.00917 0 0 0 -8 8v16a8 8 0 0 0 16 0v-16a8.00917 8.00917 0 0 0 -8-8z"
                    fill="#353535"></path>
                <path
                    d="m72 368a24.02687 24.02687 0 0 1 -24-24v-16a24 24 0 0 1 48 0v16a24.02687 24.02687 0 0 1 -24 24zm0-48a8.00917 8.00917 0 0 0 -8 8v16a8 8 0 0 0 16 0v-16a8.00917 8.00917 0 0 0 -8-8z"
                    fill="#353535"></path>
                <path
                    d="m408 104v-9.37262a16.0002 16.0002 0 0 0 -4.68628-11.31366l-3.31372-3.31372h-96l-3.31372 3.31372a16.0002 16.0002 0 0 0 -4.68628 11.31366v9.37262a8 8 0 0 0 8 8h96a8 8 0 0 0 8-8z"
                    fill="#575757"></path>
            </g>
        </svg>
    )
}